<template>
  <div>
    <div v-if="img" class="content">
      <div class="box">
        <div class="top">
          <img class="img1" :src="img" alt />
          <div class="top-phone" @click="tel()">
            <img class="phone" src="@/assets/image/phone.png" alt />
          </div>
          <div class="top-ck" @click="govneue">
            <img src="@/assets/image/地图.png" alt />
            <span>查看全景</span>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="title">{{title}}</div>
            <div class="left-bom">
              <img src="@/assets/image/时间.png" alt />
              <div class="time">开放时间: {{openStartTime}}-{{openEndTime}}</div>
            </div>
          </div>
          <div
            :id="orderStatus===0?'':orderStatus===2?'orange':orderStatus===1?'':'black'"
            class="sh"
          >{{orderStatus===2?'已通过审核':orderStatus===3?'预约失败':'待审核'}}</div>
        </div>
      </div>
      <van-cell-group inset>
        <van-field v-model="value" autosize type="textarea" rows="1" readonly label="场馆设施:" />
        <van-field v-model="value1" autosize type="textarea" rows="1" readonly label="其他说明:" />
      </van-cell-group>

      <van-cell-group inset>
        <van-field label="预约时段" readonly>
          <template #input>
            <div class="tet">
              <span>{{date}}</span>
              <span>{{time}}</span>
            </div>
          </template>
        </van-field>
        <van-field label="预约类型" v-if="flag" readonly>
          <template #input>
            <div class="tet">
              <div>{{subscribeType}}</div>
            </div>
          </template>
        </van-field>
        <van-field label="预约设备" v-if="flag" readonly>
          <template #input>
            <div class="tet">
              <div>{{chooseFacilities}}</div>
            </div>
          </template>
        </van-field>
      </van-cell-group>

      <van-cell-group inset>
        <van-field readonly v-model="value2" label="主题" v-if="flag" />
        <van-field readonly v-model="masterCompany" v-if="flag" label="主办单位" />
        <van-field readonly v-model="slaveCompany" v-if="flag" label="协办单位" />
        <van-field readonly v-model="leader" label="出席领导" />
        <van-field readonly v-model="attendance" label="出席人数" />
      </van-cell-group>

      <van-cell-group inset>
        <van-field readonly v-model="name" label="联系人" />
        <van-field readonly v-model="phone" label="联系电话" />
        <van-field readonly v-model="company" label="公司/部门" />
        <van-field readonly v-model="emil" label="电子邮箱" />
      </van-cell-group>
      <van-cell-group v-if="subscribeData" inset class="updata">
        <van-cell title="上传资料(可上传多份)" label="请上传小于15M的文件;预约路演必须上传路演PPT等文件" />
        <div>
          <a v-for="(item,index) in subscribeData" :key="index" class="file-box" :href="item">
            <img class="file" src="../../assets/image/文件.png" alt />
            <span class="filename">{{item}}</span>
          </a>
        </div>
      </van-cell-group>

      <div class="message">
        <span style="font-size:16px">备注说明/留言</span>
        <van-field v-model="message" readonly rows="2" autosize type="textarea" show-word-limit />
      </div>
      <div v-if="isReviewer" class="time-box">
        <div class="shtime">
          <span></span>
          <div>
            用户{{username}}于
            <span style="color:#2F92F8">{{applyTime}}</span> 提交了预约申请
          </div>
        </div>
        <div class="shtime" v-if="reviewer1Username">
          <span></span>
          <div>
            管理员{{reviewer1Username}}于
            <span style="color:#2F92F8">{{reviewer1Time}}</span>
            {{a}}
          </div>
        </div>
        <div class="shtime" v-if="reviewer2Username">
          <span></span>
          <div>
            管理员{{reviewer2Username}}于
            <span style="color:#2F92F8">{{reviewer2Time}}</span>
            {{b}}
          </div>
        </div>
      </div>
      <div v-else class="b-time">预约提交时间：{{applyTime}}</div>
      <div v-if="!isReviewer && orderStatus!==3 && time30" class="bottom-but">
        <div class="but" @click="cancelSubscribe">取消</div>
      </div>
      <div v-if="show">
        <div v-if="isReviewer" class="bottom-but">
          <div class="bh" @click="reviewSubscribe(0)">审核驳回</div>
          <div @click="reviewSubscribe(1)">审核通过</div>
        </div>
      </div>
    </div>
    <van-loading v-else size="24px" style="text-align:center;margin-top:70px">加载中...</van-loading>
  </div>
</template>

<script>
import {
  getSubscribeDetail,
  getSubscribeReviewDetail,
  reviewSubscribe,
  cancelSubscribe
} from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      flag: false,
      show: '',
      a: '',
      b: '',
      reviewer1Username: '',
      reviewer2Username: '',
      reviewer2Time: '',
      reviewer1Time: '',
      username: '',
      title: '',
      img: '',
      openStartTime: '',
      openEndTime: '',
      orderStatus: '',
      value: '',
      value2: '',
      value1: '',
      masterCompany: '',
      leader: '',
      slaveCompany: '',
      attendance: '',
      name: '',
      phone: '',
      company: '',
      emil: '',
      message: '',
      id: '',
      date: '',
      time: '',
      subscribeType: '',
      chooseFacilities: '',
      applyTime: '',
      subscribeData: [],
      isReviewer: '',
      orderstatus: '',
      areaPhone: '',
      link: '',
      time30: true
    }
  },
  created() {
    this.id = this.$route.query.id
    this.isReviewer = this.$route.query.isReviewer
    console.log(this.id)
    if (this.isReviewer) {
      this.getSubscribeReviewDetail()
    } else {
      this.getSubscribeDetail()
    }
  },
  methods: {
    govneue() {
      if (this.link) {
        localStorage.setItem('flag', 2)
        this.$router.push(`/ifm?link=${this.link}`)
      } else {
        localStorage.setItem('flag', 0)
        this.$router.push('/venue')
      }
    },
    tel() {
      window.location.href = 'tel:' + this.areaPhone
    },
    async cancelSubscribe() {
      const { data } = await cancelSubscribe({
        id: this.id
      })
      console.log(data)
      if (data.code === 200) {
        Toast('操作成功')
        this.$router.push('/appointment')
      } else {
        Toast(data.msg)
      }
    },
    async reviewSubscribe(a) {
      const { data } = await reviewSubscribe({
        id: this.id,
        orderStatus: a
      })
      console.log(data)
      if (data.code === 200) {
        Toast('操作成功')
        this.$router.push('/appointment')
      } else {
        Toast(data.msg)
      }
    },
    async getSubscribeReviewDetail() {
      const { data } = await getSubscribeReviewDetail({
        id: this.id
      })
      console.log(data)
      if (data.data.orderType === 0) {
        this.flag = true
        this.value = data.data.facilities
      } else {
        this.value = data.data.visitArea
      }
      this.link = data.data.vrLink
      this.areaPhone = data.data.areaPhone
      this.img = data.data.areaImg
      this.title = data.data.areaTitle
      this.openStartTime = data.data.openStartTime
      this.openEndTime = data.data.openEndTime
      this.orderStatus = data.data.orderStatus
      this.value1 = data.data.other
      this.value2 = data.data.subject
      this.slaveCompany = data.data.slaveCompany
      this.masterCompany = data.data.masterCompany
      this.leader = data.data.leader
      this.attendance = data.data.attendance
      this.name = data.data.contact
      this.company = data.data.department
      this.phone = data.data.phone
      this.emil = data.data.email
      this.message = data.data.remarks
      this.date = data.data.startTime.substring(0, 10)
      const a = data.data.startTime.substring(11)
      const b = data.data.endTime.substring(11)
      this.time = a + '-' + b
      this.subscribeType = data.data.subscribeType
      this.chooseFacilities = data.data.chooseFacilities
      this.applyTime = data.data.applyTime
      if (data.data.subscribeData) {
        this.subscribeData = data.data.subscribeData
          .substring(0, data.data.subscribeData.length - 1)
          .split(';')
      }
      this.username = data.data.username
      this.reviewer1Time = data.data.reviewer1Time
      this.reviewer2Time = data.data.reviewer2Time
      this.reviewer1Username = data.data.reviewer1Username
      this.reviewer2Username = data.data.reviewer2Username
      this.orderstatus = data.data.orderStatus
      if (this.orderstatus === 3) {
        this.show = false
        if (this.reviewer2Username) {
          this.b = '审核驳回'
          this.a = '审核通过'
        } else {
          this.a = '审核驳回'
        }
      } else if (this.orderstatus === 2) {
        this.show = false
        this.b = '审核通过'
        this.a = '审核通过'
      } else if (this.orderstatus === 1) {
        this.a = '审核通过'
        if (this.isReviewer === '1') {
          this.show = false
        } else {
          this.show = true
        }
      } else {
        if (this.isReviewer === '1') {
          this.show = true
        } else {
          this.show = false
        }
      }
    },
    async getSubscribeDetail() {
      const { data } = await getSubscribeDetail({
        id: this.id
      })
      if (data.data.orderType === 0) {
        this.flag = true
        this.value = data.data.facilities
      } else {
        this.value = data.data.visitArea
      }
      this.link = data.data.vrLink
      console.log(data.data)
      this.areaPhone = data.data.areaPhone
      this.img = data.data.areaImg
      this.title = data.data.areaTitle
      this.openStartTime = data.data.openStartTime
      this.openEndTime = data.data.openEndTime
      this.orderStatus = data.data.orderStatus
      this.value1 = data.data.other
      this.value2 = data.data.subject
      this.slaveCompany = data.data.slaveCompany
      this.masterCompany = data.data.masterCompany
      this.leader = data.data.leader
      this.attendance = data.data.attendance
      this.name = data.data.contact
      this.company = data.data.department
      this.phone = data.data.phone
      this.emil = data.data.email
      this.message = data.data.remarks
      this.date = data.data.startTime.substring(0, 10)
      const a = data.data.startTime.substring(11)
      const b = data.data.endTime.substring(11)
      this.time = a + '-' + b
      this.subscribeType = data.data.subscribeType
      this.chooseFacilities = data.data.chooseFacilities
      this.applyTime = data.data.applyTime
      // 获取当前时间的时间戳（以毫秒为单位）
      const currentTime = new Date().getTime()
      // 假设下单时间也以时间戳的形式表示
      const orderTime = new Date(data.data.applyTime).getTime()
      // 计算时间差，单位为分钟
      const timeDifferenceMinutes = (currentTime - orderTime) / (1000 * 60)
      if (timeDifferenceMinutes <= 30) {
        console.log('订单尚未超过30分钟，可以取消。')
        this.time30 = true
      } else {
        this.time30 = false
        console.log('订单已经超过30分钟，不可取消。')
      }
      if (data.data.subscribeData) {
        this.subscribeData = data.data.subscribeData
          .substring(0, data.data.subscribeData.length - 1)
          .split(';')
      }
    }
  }
}
</script>

<style lang="less" scoped>
#orange {
  color: #f8892f;
}
#black {
  color: #9d9d9d;
}
/deep/ .van-field__control {
  overflow: hidden;
}
.time-box {
  width: 84vw;
  margin-left: 8vw;
}
.shtime {
  margin: 10px 0;
  font-size: 12px;
  color: #999;
  display: flex;
  > span {
    margin-top: 5px;
    height: 5px;
    width: 5px;
    border-radius: 50% 50%;
    background-color: #f8892f;
    margin-right: 5px;
  }
}
.bottom-but {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5vw;
  font-size: 14px;

  div {
    height: 44px;
    width: 40vw;
    background-color: #f8892f;
    color: #fff;
    line-height: 44px;
    text-align: center;
    border-radius: 22px;
    border: none;
    outline: none;
  }
  .bh {
    color: #333;
    background-color: #d0d0d0;
  }
  .but {
    width: 90vw;
  }
}
.content {
  .tet {
    width: 100%;
    color: #666;
    display: flex;
    align-items: center;
    span {
      width: 30vw;
    }
    img {
      width: 10px;
    }
    .tet-img {
      margin-left: 20px;
    }
  }
  .b-time {
    font-size: 12px;
    text-align: center;
    color: #9e9e9e;
  }
  .sh {
    font-size: 14px;
    color: #2f92f8;
  }
  .updata .van-cell::after {
    border: none;
  }
  .updata > div {
    display: flex;
  }
  .file {
    height: 40px;
    margin-left: 16px;
  }
  .filename {
    display: block;
    font-size: 14px;
    color: #969696;
    width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .up {
    border-radius: 5px;
    width: 70px;
    height: 50px;
    border: 0.5px #969696 dashed;
    margin-left: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 94%;
    margin-left: 3%;
    height: 50px;
    color: #fff;
    background-color: #f8892f;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    border-radius: 25px;
    margin-top: 15px;
  }
  .message {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    height: 120px;
    border-radius: 15px;
    margin-bottom: 15px;
    span {
      font-size: 14px;
      color: #646566;
      margin-bottom: 5px;
    }
    /deep/ .van-cell {
      padding: 0;
    }
    /deep/ .van-cell__value {
      // border: 0.5px #e7e7e7 solid;
      border-radius: 10px;
      padding: 5px;
      box-sizing: border-box;
    }
    /deep/ .van-field__word-limit {
      color: #e7e7e7;
    }
  }
  padding-bottom: 15px;
  height: 100%;
  width: 100%;
  /deep/ .van-cell-group--inset {
    width: 94%;
    margin: 0 0 10px 3%;
    .van-field__label {
      width: 5.2em;
      margin-right: 0px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-radius: 12px;
    background-color: #fff;
    width: 94%;
    margin-left: 3%;
    height: 247px;
    overflow: hidden;
    .top {
      position: relative;
      .top-ck {
        position: absolute;
        bottom: 10%;
        left: 3%;
        display: flex;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        height: 23px;
        width: 90px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .top-phone {
        border: none;
        outline: none;
        height: 38px;
        width: 38px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50% 50%;
        position: absolute;
        bottom: 6%;
        right: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .img1 {
      width: 100%;
      height: 173px;
    }
    .bottom {
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px 10px 15px;
      box-sizing: border-box;
      .left {
        display: flex;
        flex-direction: column;
        align-self: left;
        .left-bom {
          display: flex;
          align-items: center;
        }
        img {
          width: 13px;
          height: 13px;
          margin-right: 3px;
        }
        .title {
          font-size: 18px;
          color: #333333;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .time {
          font-size: 14px;
          color: #969696;
        }
      }
    }
  }
  /deep/ .van-cell {
    font-size: 15px;
  }
}
</style>
